
































import { Component, Prop, Vue } from "vue-property-decorator";
import { Categories, Category, Item } from "@/types";
import { namespace } from "vuex-class";
import NumberFormat = Intl.NumberFormat;
const AppModule = namespace("AppModule");
const CategoriesModule = namespace("CategoriesModule");
@Component
export default class CardItem extends Vue {
  @Prop() readonly item!: Item;
  @CategoriesModule.State
  public categories!: Categories;
  @CategoriesModule.Getter
  public selectedCategory!: Category;
  @AppModule.State
  public currencyFormatter!: NumberFormat;
  public format(points: number) {
    return this.currencyFormatter.format(points);
  }
  public getRandomInt(max: number) {
    return Math.floor(Math.random() * Math.floor(max));
  }
  public async onClickGet() {
    await this.$router.push(`/item/${this.item.id}`);
  }
}
